// Gatsby-specific events within the browser
// https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser

import 'prism-theme-vars/base.css'
import './src/config/global.css'
import './src/config/carbonads.css'

// https://www.gatsbyjs.com/docs/how-code-splitting-works/
// Organic users (>85%) comes and goes in one page, prefetching for
// fast navigation is not neccessary and a waste of bandwidth
export const disableCorePrefetching = () => true
